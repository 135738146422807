import { name,version } from "../package.json";
import { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";
import { Scene } from "@babylonjs/core";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";

// Logic & Interface
import { Flux360Logic } from 'flux-360-logic';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360Interface } from 'flux-360-interface';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

// Configs
import logicConfig from '@/configs/config_logic.json';
import interfaceConfig from '@/configs/config_interface.json';

// Loc Version
console.log("---- Starting " + name + " [" + version + "] ----");
console.log("");

// Create Logic & Interface
let flux360Logic = new Flux360Logic(logicConfig);
let flux360Interface = new Flux360Interface(interfaceConfig);
Flux360LogicSingleton.setInstance(flux360Logic);
Flux360InterfaceSingleton.setInstance(flux360Interface);

// Setup Interface
flux360Interface.setup(flux360Logic);

Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent: SituationComponent)  => {
    if(situationComponent.getConfig().textureMediaID == 17){

        let scene =  Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene() as Scene;
        let texture = scene.getTextureByName("Center - Image Texture - 17") as Texture;
        texture.uScale = -1;
        texture.vScale = -0.995;
    }
});