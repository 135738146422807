
import { Flux360Logic } from "flux-360-logic";

export class Flux360LogicSingleton {

    private static instance: Flux360Logic;

    public static getInstance(): Flux360Logic {
        return this.instance;
    }

    public static setInstance(instance:Flux360Logic) {  
        this.instance =instance;
    }
}