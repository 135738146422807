
import { Flux360Interface } from "flux-360-interface";

export class Flux360InterfaceSingleton {

    private static instance: Flux360Interface;

    public static getInstance(): Flux360Interface {
        return this.instance;
    }

    public static setInstance(instance:Flux360Interface) {  
        this.instance =instance;
    }
}